<template>
  <div
    v-if="isProgress.page || isProgress.login || !caseDetailDataVuex"
    class="preLoader"
  >
    <span class="spin"></span>
  </div>

  <section
    v-if="caseDetailDataVuex"
    class="appContent detailPageWrapper bg-white"
  >
    <!-- isProgress: {{ isProgress }} -->
    <!-- {{ parmsId }} -->
    <div class="row gx-4">
      <div class="col-lg-12">
        <div class="d-flex align-items-center mb-3">
          <div>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/caseManagement"> 案件 </router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:;" @click="handleGo2CaseDetail()">
                  {{ caseDetailDataVuex.CaseID }}
                </a>
              </li>
              <li class="breadcrumb-item active">Workspace</li>
            </ol>
            <h1
              class="page-header mb-0"
              :class="[`pageHeader--${caseDetailDataVuex.Severity}`]"
            >
              {{ caseDetailDataVuex.CaseID }}
              {{ caseDetailDataVuex.CompanyInfo.CustomerName }}
              <span v-if="caseDetailDataVuex.State == 1">（案件已取消）</span>
              <span v-if="caseDetailDataVuex.State == 2">（已建案）</span>
            </h1>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-lg-6">
            <div class="fieldRow">
              <label
                for="lead_engineer_name"
                class="fieldRow__label text-center"
              >
                <i class="bi bi-person-fill-gear"></i>
              </label>

              <input
                v-model="caseDetailDataVuex.LeadEngineerName"
                type="text"
                id="lead_engineer_name"
                class="form-control"
                list="engineerOptions"
                readonly
                disabled
                :placeholder="initConfig.placeholder.company_name.forCaseForm"
              />
            </div>

            <div class="fieldRow">
              <label for="issue_type" class="fieldRow__label text-center">
                <i class="fbi bi-bookmark-star-fill"></i>
              </label>
              <select
                v-model="caseDetailDataVuex.IssueType"
                id="issue_type"
                class="form-select fieldRow__editField"
                disabled
              >
                <option
                  v-for="item in initConfig.field.issue_type.options"
                  :value="item.value"
                  :key="item.key"
                  :disabled="item.disabled"
                >
                  {{ item.key }}
                </option>
              </select>
            </div>

            <div class="fieldRow">
              <label for="issue_type" class="fieldRow__label text-center">
                SR Num.
              </label>
              <strong>
                <template v-if="!isEnvProduction">
                  {{
                    caseDetailDataVuex.SRCaseIdStg
                      ? caseDetailDataVuex.SRCaseIdStg
                      : "-"
                  }}
                </template>
                <template v-else>
                  {{
                    caseDetailDataVuex.SRCaseIdProd
                      ? caseDetailDataVuex.SRCaseIdProd
                      : "-"
                  }}
                </template>
              </strong>
            </div>
          </div>
          <div class="col-lg-6">
            <van-field
              v-model="caseDetailDataVuex.onsite_date_display"
              type="text"
              name="onsite_date_display"
              class="fieldRow"
              label="On-site"
              label-class="fieldRow__label text-center"
              readonly
              :border="false"
            />

            <van-field
              v-model="caseDetailDataVuex.CompanyInfo.SalesEnglishName"
              type="text"
              name="mxdr_case_number"
              class="fieldRow"
              label="趨勢業務"
              label-class="fieldRow__label text-center"
              :readonly="initConfig.field.sales_name.readonly"
              :border="false"
            />

            <div class="fieldRow">
              <label for="issue_type" class="fieldRow__label text-center">
                SR Status
              </label>
              <strong>
                <template v-if="!isEnvProduction">
                  {{
                    caseDetailDataVuex.SRCaseIdStg
                      ? caseDetailDataVuex.SRCaseStgObj.Status
                      : "-"
                  }}
                </template>
                <template v-else>
                  {{
                    caseDetailDataVuex.SRCaseIdProd
                      ? caseDetailDataVuex.SRCaseProdObj.Status
                      : "-"
                  }}
                </template>
              </strong>
            </div>
          </div>
        </div>

        <hr />
        <div class="position-relative">
          <div
            v-if="
              (activeTab == 0 && caseDetailDataVuex.state == 2) ||
              (activeTab == 1 && caseDetailDataVuex.state == 2)
            "
            class="functionalButtonWrap"
          >
            <button
              v-if="ENABLE_FUNCTION_ADD_ASSET && currentTab === 'assetsList'"
              type="button"
              class="btn btn-green me-2"
              @click="showAddNewAssetPopup()"
            >
              <i class="bi bi-plus-lg me-1"></i>
              Add New Asset
            </button>

            <button
              v-if="currentTab === 'assetsList'"
              type="button"
              class="btn btn-primary me-2"
              @click="showUploadFilesPopup(0)"
            >
              <i class="bi bi-cloud-upload me-1"></i>
              Upload Log Files
            </button>

            <button
              v-if="currentTab === 'collectFilesList2'"
              type="button"
              class="btn btn-danger"
              @click="showUploadFilesPopup(1)"
            >
              <i class="bi bi-cloud-upload me-1"></i>
              Upload Virus Samples
            </button>
          </div>

          <ul class="tabs">
            <li
              class="tabs__tab"
              :class="{ 'tabs__tab--active': currentTab === 'assetsList' }"
              @click="handleTabSwitcher('assetsList')"
            >
              主機清單 ({{ caseDetailDataVuex.Workspace.Assets.length }})
            </li>
            <!-- <li
              class="tabs__tab"
              :class="{
                'tabs__tab--active': currentTab === 'collectFilesList',
              }"
              @click="handleTabSwitcher('collectFilesList')"
            >
              檔案清單 ({{ workspaceCollectFilesDataVuex.length }})
            </li> -->
            <li
              class="tabs__tab"
              :class="{
                'tabs__tab--active': currentTab === 'collectFilesList2',
              }"
              @click="handleTabSwitcher('collectFilesList2')"
            >
              Collect Files ({{ workspaceCollectFilesDataVuex.length }})
            </li>
            <li
              class="tabs__tab"
              :class="{
                'tabs__tab--active': currentTab === 'hermesResultsList',
              }"
              @click="handleTabSwitcher('hermesResultsList')"
            >
              APT Results ({{
                workspaceAptStgResultDataVuex.length +
                workspaceAptProdResultDataVuex.length
              }})
            </li>
            <li
              v-if="ENABLE_SECTION_KEYWORD_SEARCH"
              class="tabs__tab"
              :class="{
                'tabs__tab--active': currentTab === 'keywordSearchSection',
              }"
              @click="handleTabSwitcher('keywordSearchSection')"
            >
              關鍵字搜尋
            </li>
          </ul>

          <div class="position-relative mb-5">
            <section v-if="currentTab === 'assetsList'">
              <div class="position--center">
                <van-loading
                  v-if="isProgress.mainData"
                  size="60px"
                  vertical
                ></van-loading>
              </div>
              <Table4WorkspaceAssets
                :assets-data="caseDetailDataVuex.Workspace.Assets"
                @reload-data="refreshDetailSilent"
              ></Table4WorkspaceAssets>
            </section>

            <section v-if="currentTab === 'collectFilesList2'">
              <Table4WorkspaceCollectFiles2
                :company-info="caseDetailDataVuex.CompanyInfo"
                :member-data="shiftMemberListDataVuex"
                :owner-email="caseDetailDataVuex.LeadEngineerEmail"
                :stgsrcase-id="
                  caseDetailDataVuex.SRCaseIdStg
                    ? caseDetailDataVuex.SRCaseIdStg
                    : null
                "
                :prodsrcase-id="
                  caseDetailDataVuex.SRCaseIdProd
                    ? caseDetailDataVuex.SRCaseIdProd
                    : null
                "
                :stgsrcase-status="
                  caseDetailDataVuex.SRCaseIdStg
                    ? caseDetailDataVuex.SRCaseStgObj.Status
                    : null
                "
                :prodsrcase-status="
                  caseDetailDataVuex.SRCaseIdProd
                    ? caseDetailDataVuex.SRCaseProdObj.Status
                    : null
                "
                @reload-data="refreshDetailSilent"
              ></Table4WorkspaceCollectFiles2>
            </section>
            <section
              v-if="currentTab === 'hermesResultsList'"
              :class="{ 'opacity-75': isProgress.mainData }"
            >
              <Table4WorkspaceHermesForm
                :stg-data="workspaceAptStgResultDataVuex"
                :prod-data="workspaceAptProdResultDataVuex"
                :prodsrcase-id="
                  caseDetailDataVuex.SRCaseIdProd
                    ? caseDetailDataVuex.SRCaseIdProd
                    : null
                "
                :prodsrcase-status="
                  caseDetailDataVuex.SRCaseIdProd
                    ? caseDetailDataVuex.SRCaseProdObj.Status
                    : null
                "
              ></Table4WorkspaceHermesForm>
            </section>
            <section
              v-if="currentTab === 'keywordSearchSection'"
              :class="{ 'opacity-75': isProgress.mainData }"
            >
              <Table4WorkspaceSearch
                :asset-length="caseDetailDataVuex.Workspace.Assets.length"
                :file-length="workspaceCollectFilesDataVuex.length"
              ></Table4WorkspaceSearch>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div class="functionBar functionBar--fixed">
      <button type="button" class="btn btn-link" @click="handleGo2CaseDetail()">
        離開
      </button>
      <div>
        <!-- <button
          v-if="caseDetailDataVuex.state == 2"
          type="button"
          class="btn btn-success me-3"
          :disabled="caseDetailDataVuex.state == 1"
          @click.stop="handleOpenSendHermesPopup()"
        >
          Send HERMES
        </button>
        <button
          v-if="caseDetailDataVuex.state == 2"
          type="button"
          class="btn btn-primary"
          @click.stop="handleSaveWorkspaceData(caseDetailDataVuex.CaseID)"
        >
          保存
        </button>
        <button
          v-if="caseDetailDataVuex.state == 1"
          type="button"
          class="btn btn-danger"
          disabled="!managerWhiteListVuex.includes(userEmail)"
          @click.stop="showReopenCasePopup()"
        >
          Reopen
        </button> -->
      </div>
    </div>
  </section>

  <van-popup
    v-model:show="popupUploadLogFiles"
    class="popup popup__right popup__right--xmedium"
    position="right"
    :close-on-click-overlay="false"
    @click-close-icon="handleClickUploadPopupCloseIcon"
  >
    <div
      class="popup__header d-flex justify-content-between align-items-center"
    >
      {{
        uploadType == 0
          ? "Upload Log Files"
          : uploadType == 1
          ? "Upload Virus Samples"
          : "Add New Asset"
      }}
      <i
        class="fa-solid fa-x"
        style="cursor: pointer"
        @click="handleClickUploadPopupCloseIcon"
      ></i>
    </div>
    <!-- newCaseRecord: {{ newCaseRecord }} -->
    <div class="popup__content">
      <div class="alert alert-primary" role="alert">
        <ul style="margin-left: 8px; list-style: circle">
          <li>
            檔案上傳成功後，解壓縮程序需耗時約3分鐘。解壓縮完畢該筆"上傳"狀態會顯示為成功。
          </li>
          <li>相同檔名壓縮檔上傳，會覆寫前一次的檔案內容。</li>
          <li>
            檔案上傳完成後需等候約五分鐘，資料才會納入關鍵字搜尋資料庫內。
          </li>
        </ul>
      </div>
      <div v-if="isProgress.uploadFiles" class="loading__wrapper">
        <van-loading
          class="text-center text-black"
          style="margin-top: 20%"
          size="60"
        />
      </div>
      <FileUploadPlayGround
        :key="popupUploadLogFiles"
        :upload-type="uploadType"
        :case-id="caseDetailDataVuex.CaseID"
        @active-refresh="intervalHandle"
        @reload-data="refreshDetailSilent"
        @show-popup="(boolean) => (popupUploadLogFiles = boolean)"
        @set-status="setUploadZipfileStatus"
      />
    </div>
  </van-popup>

  <van-popup
    v-model:show="popupAddNewAsset"
    class="popup popup__right popup__right--medium"
    position="right"
    closeable
    :close-on-click-overlay="false"
  >
    <AddAssetTemplate
      @showPopup="(boolean) => (popupAddNewAsset = boolean)"
      @reload-data="refreshDetailSilent"
    />
  </van-popup>

  <van-dialog
    v-model:show="popupAlertDialog"
    title=""
    show-confirm-button
    z-index="2300"
    message="無此案件"
    confirm-button-text="返回"
    @confirm="handleClickTurningBackButton()"
  ></van-dialog>
</template>

<script>
// @ is an alias to /src
import {
  ref,
  reactive,
  computed,
  watch,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { showDialog, showConfirmDialog, closeDialog } from "vant";

import AddAssetTemplate from "./../components/AddAssetTemplate.vue";
import Table4WorkspaceAssets from "./../components/Table4WorkspaceAssets.vue";
// import Table4WorkspaceCollectFiles from "./../components/Table4WorkspaceCollectFiles.vue";
import Table4WorkspaceHermesForm from "./../components/Table4WorkspaceHermesForm.vue";
import Table4WorkspaceCollectFiles2 from "./../components/Table4WorkspaceCollectFiles2.vue";
import Table4WorkspaceSearch from "./../components/Table4WorkspaceSearch.vue";
import FileUploadPlayGround from "./FileUploadPlayGround.vue";

import { special } from "../special";
import { form_config } from "../form.config";

export default {
  name: "WorkspaceDetail",
  components: {
    FileUploadPlayGround,
    AddAssetTemplate,
    Table4WorkspaceAssets,
    // Table4WorkspaceCollectFiles,
    Table4WorkspaceCollectFiles2,
    Table4WorkspaceHermesForm,
    Table4WorkspaceSearch,
  },
  setup() {
    const ENABLE_FUNCTION_ADD_ASSET =
      special.ENABLE_FUNCTION_ADD_ASSET || false;
    const ENABLE_SECTION_KEYWORD_SEARCH =
      special.ENABLE_SECTION_KEYWORD_SEARCH || false;
    const isEnvProduction = ref(null);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    // eslint-disable-next-line no-undef
    const initConfig = form_config || [];
    let parmsId = ref(null);
    const popupComment = ref(false);
    const popupDeleteCommentConfirm = ref(false);
    const popupAlertDialog = ref(false);
    const popupUploadLogFiles = ref(false);
    const popupAddNewAsset = ref(false);

    const currentTab = ref(
      sessionStorage.getItem("workSpace_currentTab") || "assetsList"
    );

    const caseComment = ref(null);
    const deleteCommentTargetId = ref(null);
    const userEmail = ref(null);
    const uploadType = ref(null);
    const activeTab = ref(0);
    const intervalHandle = ref(null);

    const refreshDetailDirectly = () => {
      getCaseDetailData(parmsId);
    };
    const refreshDetailSilent = () => {
      getCaseDetailDataSilent(parmsId);
      getCollectFilesData(parmsId);
    };
    const stopAutoRefresh = () => {
      console.log("stopAutoRefresh");
      clearInterval(intervalHandle.value);
    };

    const workspaceForm = reactive({
      lead_engineer_name: null,
      lead_engineer_email: null,
      onsite_date: null,
      issue_type: null,
      sales_name: null,
      sales_email: null,
      assets: [],
      uploaded_files: [],
    });
    const comment2Send = reactive({
      submitter_email: "",
      comment: "",
    });

    const userData = computed(() => {
      return store.getters.user;
    });
    const isProgress = computed(() => {
      return store.getters.isProgress;
    });
    const managerWhiteListVuex = computed(() => {
      return store.getters.managerWhiteList;
    });
    const shiftMemberListDataVuex = computed(() => {
      return store.getters.shiftMemberListData;
    });
    const caseDetailDataVuex = computed(() => {
      return store.getters.caseDetailData;
    });
    const workspaceCollectFilesDataVuex = computed(() => {
      return store.getters.workspaceCollectFilesData;
    });
    const caseSearchResultDataVuex = computed(() => {
      return store.state.keywordResult;
    });
    const workspaceAptStgResultDataVuex = computed(() => {
      return store.state.workspaceAptStgResultsData;
    });
    const workspaceAptProdResultDataVuex = computed(() => {
      return store.state.workspaceAptProdResultsData;
    });
    const optionsAllIrEngineersNameListVuex = computed(() => {
      return store.getters.optionsAllIrEngineersNameList;
    });

    const showCommentPopup = () => {
      caseComment.value = "";
      popupComment.value = true;
    };
    const showDeleteCommentConfirmPopup = (commentId) => {
      deleteCommentTargetId.value = commentId;
      popupDeleteCommentConfirm.value = true;
    };
    const showUploadFilesPopup = (type) => {
      console.log("showUploadFilesPopup");
      stopAutoRefresh();
      uploadType.value = type;
      popupUploadLogFiles.value = true;
      console.log("userData.value - showUploadFilesPopup", userData.value);
      const validateLoginStatus = userData.value.data ? true : false;
      console.log(
        "validateLoginStatus - showUploadFilesPopup",
        validateLoginStatus
      );

      if (!validateLoginStatus) {
        console.log("router.currentRoute", router.currentRoute.value.fullPath);
        sessionStorage.setItem(
          "SWS_pageRedirect",
          router.currentRoute.value.fullPath
        );
        stopAutoRefresh();
        showDialog({
          message: "登入認證失敗，請重新登入",
          confirmButtonText: "確認",
          zIndex: "2007",
        }).then(() => {
          // on close
          router.push("/login");
        });
      }
    };
    const showAddNewAssetPopup = () => {
      console.log("showAddNewAssetPopup");
      popupAddNewAsset.value = true;
    };

    const handleGo2CaseManagement = () => {
      router.push("/caseManagement");
    };
    const handleGo2CaseDetail = () => {
      router.push(`/caseDetail/${parmsId.value}`);
    };
    const handleSendAlertComment = async () => {
      comment2Send.submitter_email = userData.value.data.mail;
      comment2Send.comment = caseComment.value;

      const comment2SendPackage = {
        caseId: caseDetailDataVuex.value.Alert_id,
        data: comment2Send,
      };

      console.log("comment2SendPackage", comment2SendPackage);

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("postAlertComment", comment2SendPackage);

        setTimeout(() => {
          // location.reload();
          popupComment.value = false;
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Send Comment FAIL", err);
      }
    };
    const handleUpdateAlertComment = async (commentId) => {
      comment2Send.submitter_email = userData.value.data.mail;
      comment2Send.comment = document.getElementById(
        `commentTextarea--${commentId}`
      ).value;

      const comment2SendPackage = {
        caseId: caseDetailDataVuex.value.Alert_id,
        commentId: commentId,
        data: comment2Send,
      };

      console.log("comment2SendPackage", comment2SendPackage);

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("updateAlertComment", comment2SendPackage);

        setTimeout(() => {
          // location.reload();
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Update comment FAIL", err);
      }
    };
    const handleOpenCommentEditor = async (commentId) => {
      let allCommentEditor = document.querySelectorAll(
        ".commentHistory__editor"
      );
      let allCommentDisplay = document.querySelectorAll(
        ".commentHistory__body"
      );
      allCommentEditor.forEach(function (item) {
        item.style.display = "none";
      });
      allCommentDisplay.forEach(function (item) {
        item.style.display = "block";
      });
      let targetEditorDom = document.getElementsByClassName(
        `comment--${commentId}`
      );
      let targetDisplayDom = document.getElementsByClassName(
        `commentDisplay--${commentId}`
      );
      targetEditorDom[0].style.display = "block";
      targetDisplayDom[0].style.display = "none";
    };
    const handleCloseCommentEditor = async () => {
      let allCommentEditor = document.querySelectorAll(
        ".commentHistory__editor"
      );
      let allCommentDisplay = document.querySelectorAll(
        ".commentHistory__body"
      );
      allCommentEditor.forEach(function (item) {
        item.style.display = "none";
      });
      allCommentDisplay.forEach(function (item) {
        item.style.display = "block";
      });
    };
    const handleDeleteCommentPopup = async () => {
      comment2Send.submitter_email = userData.value.data.mail;

      const comment2SendPackage = {
        caseId: caseDetailDataVuex.value.Alert_id,
        commentId: deleteCommentTargetId.value,
        data: comment2Send,
      };

      console.log("comment2SendPackage", comment2SendPackage);
      popupDeleteCommentConfirm.value = false;

      try {
        store.dispatch("handlePageLoadingState", true);
        await store.dispatch("deleteAlertComment", comment2SendPackage);

        setTimeout(() => {
          popupDeleteCommentConfirm.value = false;
          // location.reload();
          getCaseDetailData(parmsId);
          store.dispatch("handlePageLoadingState", false);
        }, 2000);
      } catch (err) {
        console.log("Delete comment FAIL", err);
      }
    };
    const handleClickTurningBackButton = () => {
      router.push("/caseManagement");
    };
    const handleClickUploadPopupCloseIcon = () => {
      if (document.getElementsByClassName("filePreviewRow").length == 0) {
        popupUploadLogFiles.value = false;
        // Interval Execute
        console.log("Interval Execute");
        intervalHandle.value = setInterval(() => {
          getCaseDetailDataSilent(parmsId);
          getCollectFilesData(parmsId);
        }, 25000);
        return false;
      }
      showConfirmDialog({
        title: "將清除上傳清單佇列",
        message: "",
        confirmButtonText: "確認",
        cancelButtonText: "取消",
      })
        .then(() => {
          console.log("confirm");
          popupUploadLogFiles.value = false;
          // Interval Execute
          console.log("Interval Execute");
          intervalHandle.value = setInterval(() => {
            getCaseDetailDataSilent(parmsId);
            getCollectFilesData(parmsId);
          }, 25000);
        })
        .catch(() => {
          console.log("cancel");
        });
      console.log("handleClickUploadPopupCloseIcon");
    };
    const handleOpenSendHermesPopup = (caseId) => {
      console.log("Send HERMAS case id:", caseId);
    };
    const handleSaveWorkspaceData = (caseId) => {
      console.log("Save case id:", caseId);
    };
    const handleTabSwitcher = (tabName) => {
      currentTab.value = tabName;
      sessionStorage.setItem("workSpace_currentTab", tabName);
    };

    const getCaseDetailData = async (parmsId) => {
      store.dispatch("handlePageLoadingState", true);
      console.log(
        "execute getCaseDetailData in WorkspaceDetail - parmsId",
        parmsId
      );
      try {
        store.dispatch("handlePageLoadingState", true);
        const response = await store.dispatch(
          "getCaseDetailData",
          parmsId.value
        );

        console.log("getCaseDetailData - response.status", response.status);
        if (response.status == 200) {
          if (!Object.keys(response).length) {
            popupAlertDialog.value = true;
            console.log("No data!!!");
          } else {
            console.log("Has data!!! - response", response);
            closeDialog();
            if (response.data.State == 0) {
              router.push(`/caseEdit/${parmsId.value}`);
            }
          }
          store.dispatch("handlePageLoadingState", false);
        } else {
          store.dispatch("handlePageLoadingState", false);
          showDialog({
            message: response.message,
            confirmButtonText: "返回",
          }).then(() => {
            // on close
            router.push(`/caseManagement`);
          });
        }
      } catch (error) {
        console.log("error - getCaseDetailData", error);
        store.dispatch("handlePageLoadingState", false);
        showDialog({
          message: error.message,
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          router.push(`/caseManagement`);
        });
        // error.value = error.message;
      }
    };

    const getCaseDetailDataSilent = async (parmsId) => {
      console.log("getCaseDetailDataSilent - parmsId", parmsId);
      store.dispatch("handlePartialDataLoadingState", true);
      try {
        const response = await store.dispatch(
          "getCaseDetailDataSilent",
          parmsId.value
        );

        console.log("getCaseDetailData - response.status", response.status);
        if (response.status == 200) {
          if (!Object.keys(response).length) {
            popupAlertDialog.value = true;
            console.log("No data!!!");
          } else {
            console.log("Has data!!! - response", response);
            closeDialog();
            if (response.data.State == 0) {
              router.push(`/caseEdit/${parmsId.value}`);
            }
          }
          store.dispatch("handlePartialDataLoadingState", false);
        } else {
          store.dispatch("handlePartialDataLoadingState", false);
          showDialog({
            message: response.message,
            confirmButtonText: "返回",
          }).then(() => {
            // on close
            router.push("/caseManagement");
          });
        }
      } catch (error) {
        console.log("error - getCaseDetailData", error);
        showDialog({
          message: error.message,
          confirmButtonText: "返回",
        }).then(() => {
          // on close
          store.dispatch("handlePartialDataLoadingState", false);
          router.push(`/caseManagement`);
        });
        // error.value = error.message;
      }
      store.dispatch("handlePageLoadingState", false);
    };

    const getCollectFilesData = async (parmsId) => {
      // store.dispatch("handlePageLoadingState", true);
      try {
        const response = await store.dispatch(
          "getCollectFilesData",
          parmsId.value
        );

        console.log("getCollectFilesData - response.status", response.status);
        if (response.status == 200) {
          if (!Object.keys(response).length) {
            console.log("No data!!!");
          } else {
            console.log("Has data!!! - response", response);
          }
          // store.dispatch("handlePageLoadingState", false);
        } else {
          // store.dispatch("handlePageLoadingState", false);
          showDialog({
            message: response.message,
            confirmButtonText: "返回",
          }).then(() => {
            // on close
          });
        }
      } catch (error) {
        console.log("error - getCollectFilesData", error);
        // store.dispatch("handlePageLoadingState", false);
        showDialog({
          message: error.message,
          confirmButtonText: "返回",
        }).then(() => {
          // on close
        });
      }
    };

    const setUploadZipfileStatus = () => {
      // Todo
      console.log("Update UploadZipFile -> Status Manually");
    };

    const methodThatForcesUpdate = () => {
      // ...
      const instance = getCurrentInstance();
      instance.proxy.forceUpdate();
      // ...
    };

    watch(
      route,
      () => {
        parmsId.value = route.params.caseId;
      },
      { deep: true, immediate: true }
    );

    // watch(
    //   caseDetailDataVuex,
    //   (data) => {
    //     if (data) {
    //       console.log("caseDetailDataVuex has data.", data);
    //     } else {
    //       console.log("caseDetailDataVuex has NO data.", data);
    //       getCaseDetailData(parmsId);
    //     }
    //   },
    //   { deep: true, immediate: true }
    // );

    watch(
      userData,
      (data) => {
        if (data.data) {
          console.log("Login data coming!", data);
          // userEmail.value = data.data.mail.toLowerCase();
          userEmail.value = "lucas_chen@trend.com";
          // userEmail.value = data.data.email.toLowerCase();
        } else {
          userEmail.value = "";
        }
      },
      { deep: true, immediate: true }
    );

    watch(
      caseDetailDataVuex,
      (data) => {
        if (data) {
          if (data.State == 0) {
            console.log("Case has not confirmed", data.State);
            router.push(`/caseEdit/${parmsId.value}`);
          }
        }
      },
      { deep: true, immediate: true }
    );

    onBeforeUnmount(() => {
      clearInterval(intervalHandle.value);
    });

    onMounted(() => {
      if (!caseDetailDataVuex.value) {
        getCaseDetailData(parmsId);
      } else {
        if (caseDetailDataVuex.value.CaseID !== parmsId.value) {
          getCaseDetailData(parmsId);
        }
        store.dispatch("handlePageLoadingState", false);
      }

      getCollectFilesData(parmsId);
      // Interval Execute
      console.log("Interval Execute");
      intervalHandle.value = setInterval(() => {
        getCaseDetailDataSilent(parmsId);
        getCollectFilesData(parmsId);
      }, 25000);

      if (process.env.NODE_ENV == "production") {
        isEnvProduction.value = true;
      } else {
        isEnvProduction.value = false;
      }

      console.log("isEnvProduction", isEnvProduction.value);
    });

    return {
      ENABLE_FUNCTION_ADD_ASSET,
      ENABLE_SECTION_KEYWORD_SEARCH,
      parmsId,
      isProgress,
      uploadType,
      activeTab,
      popupComment,
      popupDeleteCommentConfirm,
      popupAlertDialog,
      popupUploadLogFiles,
      popupAddNewAsset,
      showCommentPopup,
      showDeleteCommentConfirmPopup,
      showUploadFilesPopup,
      showAddNewAssetPopup,
      currentTab,
      getCaseDetailData,
      getCaseDetailDataSilent,
      handleGo2CaseManagement,
      handleGo2CaseDetail,
      handleSendAlertComment,
      handleUpdateAlertComment,
      handleOpenCommentEditor,
      handleCloseCommentEditor,
      handleDeleteCommentPopup,
      handleClickTurningBackButton,
      handleClickUploadPopupCloseIcon,
      handleOpenSendHermesPopup,
      handleSaveWorkspaceData,
      handleTabSwitcher,
      methodThatForcesUpdate,
      caseDetailDataVuex,
      caseSearchResultDataVuex,
      managerWhiteListVuex,
      shiftMemberListDataVuex,
      optionsAllIrEngineersNameListVuex,
      workspaceCollectFilesDataVuex,
      workspaceAptStgResultDataVuex,
      workspaceAptProdResultDataVuex,
      initConfig,
      workspaceForm,
      userData,
      caseComment,
      deleteCommentTargetId,
      userEmail,
      intervalHandle,
      stopAutoRefresh,
      refreshDetailDirectly,
      refreshDetailSilent,
      setUploadZipfileStatus,
      getCollectFilesData,
      isEnvProduction,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/_tableLite.css";
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-form-placholder-color);
}
.commentHistory {
  padding-bottom: 16px;
  &__header {
    padding-bottom: 8px;
  }
  &__body {
    padding: 16px 24px;
    background-color: #f5f5f5;
    border: 1px solid #dadada;
    border-radius: 5px;
  }
  &__textarea {
    padding: 16px 24px;
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #dadada;
    border-radius: 5px;
  }
}
.loading__wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 999999;
}
.tabs {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 32px;
  &__tab {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    padding: 0 12px;
    font-size: 18px;
    color: #9d9d9d;
    line-height: var(--van-tab-line-height);
    &--active {
      color: var(--van-tab-active-text-color);
      font-size: 20px;
      font-weight: var(--van-font-bold);
    }
    &--active::before {
      content: "";
      position: absolute;
      bottom: -16px;
      width: 30%;
      height: 4px;
      background-color: var(--van-tab-active-text-color);
      border-radius: 4px;
    }
  }
}
.functionalButtonWrap {
  position: absolute;
  right: 0;
  top: 0;
  // z-index: 9;
  z-index: 0;
}
.vue3-easy-data-table__body tr td i {
  font-size: 16px;
}
.vue3-easy-data-table__body tr td .fieldRow {
  padding: 0;
}
.vue3-easy-data-table__body tr td.expand .card-header {
  font-size: 14px;
}
.tableAssets {
  font-size: 13px;
}
.van-field__error-message {
  display: block;
  width: 100%;
  margin-left: 140.4px !important;
  margin-top: -8px;
  line-height: 24px;
}
</style>
<style scoped>
:deep(.vue3-easy-data-table__main.hoverable tr:hover td.expand) {
  background-color: transparent;
}
:deep(.van-tabs__content) {
  padding-top: 16px;
}
:deep(.van-tab__text) {
  color: #9d9d9d;
  font-size: 18px;
}
:deep(.van-tab--active .van-tab__text) {
  color: #000000;
  font-size: 20px;
}
:deep(.van-tab--shrink) {
  padding: 0 var(--van-padding-sm);
}
:deep(
    .van-tabs__nav--line.van-tabs__nav--shrink,
    .van-tabs__nav--line.van-tabs__nav--complete
  ) {
  padding-left: 0;
}

/* DataTable */
::v-deep(.vtl-table) {
  display: table;
}
::v-deep(.vtl-table thead th) {
  text-align: center;
  font-weight: 400;
}
::v-deep(.vtl-table thead th.hide) {
  display: none;
}
::v-deep(.vtl-table td) {
  word-wrap: break-word;
  word-break: break-all;
  /* white-space: pre-line; */
}
::v-deep(.vtl-table td a) {
  color: #212529;
}
::v-deep(.vtl-table td.hide) {
  display: none;
}
::v-deep(.vtl-table td.overflowCell div) {
  overflow: auto;
}
::v-deep(.vtl-table td.overflowCell span) {
  position: relative;
  left: -11px;
}
</style>
